import React from "react";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import { injectIntl, Link } from "gatsby-plugin-intl";
import SVG from "react-inlinesvg";

// import { BoldCopy, BoldCopyCss } from "./content";

import GrafiaLogo from "../images/grafia.svg";
// import AntalisLogo from "../images/logo/antalis.png";
// import BalansiaLogo from "../images/logo/balansia.svg";
import HabitareLogo from "../images/logo/habitare.svg";
// import IlmapallokeskusLogo from "../images/logo/ilmapallokeskus.svg";
import MarkprintLogo from "../images/logo/markprint_black.svg";
// import OutdoorLogo from "../images/logo/outdoor.svg";
// import PogoLogo from "../images/logo/pogo-white.svg";
// import PureWasteLogo from "../images/logo/pure-waste-2.svg";
import SoundLogo from "../images/logo/sound-engine-white-3.svg";
import DeedLogo from "../images/logo/deed.svg";
// import SanomaLogo from "../images/logo/sanoma.png";
// import HakolaLogo from "../images/logo/hakola.svg";
import HdwLogo from "../images/logo/hdw.svg";
// import TomiLogo from "../images/logo/tl-white.png";
import Instagram from "../images/social-instagram-3.svg";

const Footer = ({ intl }) => {
    // console.log(intl)
    return (
        <StyledFooter>
            <StyledTop>
                <StyledTopCol>
                    <StyledInstagram>
                        <a href="https://www.instagram.com/huiputfestival/" title="Instagram" target="_blank">
                            <SVG src={Instagram} />
                        </a>
                    </StyledInstagram>
                </StyledTopCol>
                <StyledTopCol>
                    <StyledUppercase as="p">
                        #huiputcreativefestival
                        {/* {intl.messages.date} {intl.messages.location_1} {intl.messages.location_2} */}
                    </StyledUppercase>
                </StyledTopCol>
                <StyledTopCol>
                    <StyledGrafiaLogo>
                        <a href="https://grafia.fi/" title="Grafia" target="_blank">
                            <SVG src={GrafiaLogo} />
                        </a>
                    </StyledGrafiaLogo>
                </StyledTopCol>
            </StyledTop>
            <StyledDivider />
            <StyledUppercase as={StyledHeading}>
                {intl.messages.partners}
            </StyledUppercase>
            <StyledLogoGrid>
                {/* <StyledLogoGridCol>
                    <a href="https://www.antalis.fi/" title="Antalis" target="_blank">
                        <StyledAntalisLogo src={AntalisLogo} type="image/svg+xml" />
                    </a>
                </StyledLogoGridCol> */}
                {/* <StyledLogoGridCol>
                    <a href="https://balansiafilms.com/" title="Balansia Films" target="_blank">
                        <StyledBalansiaLogo src={BalansiaLogo} type="image/svg+xml" />
                    </a>
                </StyledLogoGridCol> */}
                <StyledLogoGridCol>
                    <a href="https://habitare.messukeskus.com/" title="Habitare" target="_blank">
                        <StyledHabitareLogo src={HabitareLogo} type="image/svg+xml" style={{ maxHeight: '50px' }} />
                    </a>
                </StyledLogoGridCol>
                {/* <StyledLogoGridCol>
                    <a href="https://www.hakola.fi/" title="Hakola" target="_blank">
                        <StyledHakolaLogo src={HakolaLogo} type="image/svg+xml" />
                    </a>
                </StyledLogoGridCol> */}
                <StyledLogoGridCol>
                    <a href="https://www.helsinkidesignweek.com/" title="Helsinki Design Week" target="_blank">
                        <StyledHdwLogo src={HdwLogo} type="image/svg+xml" />
                    </a>
                </StyledLogoGridCol>
                {/* <StyledLogoGridCol>
                    <a href="https://www.ilmapallokeskus.fi/" title="Ilmapallokeskus" target="_blank">
                        <StyledIlmapalloLogo src={IlmapallokeskusLogo} type="image/svg+xml" />
                    </a>
                </StyledLogoGridCol> */}
                <StyledLogoGridCol>
                    <a href="https://www.markprint.fi/" title="Markprint" target="_blank">
                        <StyledMarkprintLogo src={MarkprintLogo} type="image/svg+xml" />
                    </a>
                </StyledLogoGridCol>
                {/* <StyledLogoGridCol>
                    <a href="http://www.outdoorfinland.fi/" title="Outdoor Finland" target="_blank">
                        <SVG src={OutdoorLogo} type="image/svg+xml" />
                    </a>
                </StyledLogoGridCol> */}
                {/* <StyledLogoGridCol>
                    <a href="https://paga.nu/" title="Pågå" target="_blank">
                        <StyledPogoLogo src={PogoLogo} type="image/svg+xml" />
                    </a>
                </StyledLogoGridCol> */}
                {/* <StyledLogoGridCol>
                    <a href="https://www.purewaste.org/" title="Pure Waste" target="_blank">
                        <SVG src={PureWasteLogo} type="image/svg+xml" />
                    </a>
                </StyledLogoGridCol> */}
                <StyledLogoGridCol>
                    <a href="https://soundengine.fi/" title="Sound Engine" target="_blank">
                        <StyledSoundLogo src={SoundLogo} type="image/svg+xml" />
                    </a>
                </StyledLogoGridCol>
                <StyledLogoGridCol>
                    <a href="https://deedcreative.com/" title="Deed Creative" target="_blank">
                        <StyledDeedLogo src={DeedLogo} type="image/svg+xml" />
                    </a>
                </StyledLogoGridCol>
                {/* <StyledLogoGridCol>
                    <a href="https://www.sanoma.fi/" title="Sanoma" target="_blank">
                        <StyledSanomaLogo src={SanomaLogo} />
                    </a>
                </StyledLogoGridCol> */}
                {/* <StyledLogoGridCol>
                    <a href="https://tomileppanen.com/" title="Tomi Leppänen" target="_blank">
                        <img src={TomiLogo} style={{ maxHeight: '60px' }} />
                    </a>
                </StyledLogoGridCol> */}
            </StyledLogoGrid>
            <StyledDivider />
            <StyledUppercase as={StyledCentered}>
                {intl.messages.previous_festivals}: {" "}
                <a href="http://festival.vuodenhuiput.fi/2017/" className="link">2017</a> | <a href="http://festival.vuodenhuiput.fi/2018/" className="link">2018</a> | <a href="http://festival.vuodenhuiput.fi/2019/" className="link">2019</a> | <a href="http://festival.vuodenhuiput.fi/2020/" className="link">2020</a>
            </StyledUppercase>
        </StyledFooter>
    );
};

const StyledInstagram = styled.div`
    max-height: 35px;
    max-height: 35px;

    svg {
        width: 35px;
        height: 35px;
        filter: invert(1);
    }

    ${breakpoint("lg")`
        max-height: 40px;

        svg {
            width: 40px;
            height: 40px;
        }
    `}
`;

// const StyledAntalisLogo = styled.img`
//     max-height: 35px !important;
//     max-height: 35px !important;

//     ${breakpoint("lg")`
//         max-height: 35px !important;
//     `}
// `;

// const StyledBalansiaLogo = styled.img`
//     max-height: 18px !important;
//     max-height: 18px !important;

//     ${breakpoint("lg")`
//         max-height: 16px !important;
//     `}
// `;

const StyledHabitareLogo = styled(SVG)`
    max-height: 30px !important;
    max-height: 30px !important;

    ${breakpoint("lg")`
        max-height: 30px !important;
    `}
`;

// const StyledHakolaLogo = styled(SVG)`
//     max-height: 20px !important;
//     max-height: 20px !important;

//     ${breakpoint("lg")`
//         max-height: 20px !important;
//     `}
// `;

const StyledHdwLogo = styled(SVG)`
    max-height: 50px !important;
    max-height: 50px !important;

    ${breakpoint("lg")`
        max-height: 60px !important;
    `}
`;

// const StyledIlmapalloLogo = styled(SVG)`
//     max-height: 45px !important;
//     max-height: 45px !important;

//     ${breakpoint("lg")`
//         max-height: 45px !important;
//     `}
// `;

const StyledMarkprintLogo = styled(SVG)`
    max-height: 25px !important;
    max-height: 25px !important;

    ${breakpoint("lg")`
        max-height: 32px !important;
    `}
`;

const StyledSoundLogo = styled(SVG)`
    max-height: 25px !important;
    max-height: 25px !important;

    ${breakpoint("lg")`
        max-height: 32px !important;
    `}
`;
const StyledDeedLogo = styled.img`
    max-height: 30px !important;
    max-height: 30px !important;

    ${breakpoint("lg")`
        max-height: 32px !important;
    `}
`;

// const StyledPogoLogo = styled(SVG)`
//     max-height: 45px !important;
//     max-height: 45px !important;

//     ${breakpoint("lg")`
//         max-height: 50px !important;
//     `}
// `;

// const StyledSanomaLogo = styled.img`
//     max-height: 28px !important;
//     max-height: 28px !important;

//     ${breakpoint("lg")`
//         max-height: 30px !important;
//     `}
// `;


const StyledGrafiaLogo = styled.div`
    width: 80px;
    margin: 20px auto;

    svg {
        width: 100%;
        height: auto;
    }

    ${breakpoint("lg")`
        width: 100px;
        margin: 0 auto;
    `}
`;

const StyledUppercase = styled.div`
    text-transform: uppercase;
`;

const StyledFooter = styled.div`
    background-color: var(--color-black);
    color: var(--color-white);
    padding: 50px 25px 120px;
    letter-spacing: 0.06em;
    overflow: hidden;
    font-size: 18px;

    ${breakpoint("lg")`
        padding: 50px;
    `}

    p {
        margin-block-start: 0;
        margin-block-end: 0;
    }
    a {
        color: inherit;

        &:hover {
            color: inherit;

            &:after {
                background-color: var(--color-white);
            }
        }
    }

    img, svg {
        mix-blend-mode: screen;
    }

    *:focus {
        outline: none;
        border: 0 solid transparent;
        border-radius: 3px;
        box-shadow: 0 0 0 3px var(--color-white);
    }
`;

const StyledTop = styled.div`
    width: 100%;
    text-align: center;
   margin-bottom: 1em;

    ${breakpoint("md")`
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    `}
`;

const StyledTopCol = styled.div`
   p {
       padding-bottom: 0;
   }

    ${breakpoint("md")`
        flex-basis: 33%;
        text-align: center;

        p {
            padding-bottom: 0;
        }
    `}
`;

const StyledLogoGrid = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    ${breakpoint("lg")`
        width: 100%;
        flex-wrap: nowrap;
    `}
`;

const StyledLogoGridCol = styled.div`
    padding: 0 20px;
    text-align: center;
    flex-basis: calc(100% / 5);

    ${breakpoint("md")`
        flex-basis: calc(100% / 7);
    `}

    ${breakpoint("md")`
        flex-basis: calc(100% / 7);

        &:first-child {
            padding-left: 0;
        }
        &:last-child {
            padding-right: 0;
        }
    `}

    ${breakpoint("xl")`
        flex-basis: calc(100% / 11);
    `}



    a {
        display: block;
        margin: 0 auto;
    }

    img {
        width: auto;
        max-width: 120px;
        height: auto;
        max-height: 30px;
        margin: 20px auto;

        ${breakpoint("lg")`
            width: auto;
            max-width: 240px;
            height: auto;
            max-height: 55px;
            margin: 0;
        `}
    }
    svg {
        width: auto;
        max-width: 120px;
        height: auto;
        max-height: 40px;
        margin: 20px auto;

        ${breakpoint("lg")`
            width: 100%;
            max-width: 240px;
            height: auto;
            max-height: 55px;
            margin: 0px;
        `}
    }
`;

const StyledDivider = styled.div`
    height: 0;
    margin: 40px 0;
`;

const StyledHeading = styled.p`
    padding-bottom: 20px;
    text-align: center;
`;

const StyledCentered = styled.p`
    text-align: center;
`;


export default injectIntl(Footer);
